











































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true,
    },
    feeGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    participantGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    discounts: {
      type: Array,
      required: false,
      default: () => [],
    },
    services: {
      type: Array,
      required: false,
      default: () => [],
    },
    hotels: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      currency: "",
    });

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const rooms = computed(() => {
      if (props.hotels && props.hotels.length) {
        const hotels = props.hotels
          .map((el: any) =>
            el.rooms.map((item: any) => ({
              hotelIsEnabled: el.isEnabled,
              hotelName: el.location.name,
              roomIsEnabled: item.isEnabled,
              id: item.id,
              name: item.name,
            }))
          )
          .flat()
          .filter((el: any) => el.hotelIsEnabled && el.roomIsEnabled);
        return hotels;
      } else [];
    });

    return { state, rooms };
  },
});
